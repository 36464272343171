<template>
  <div>
    <v-card class="elevation-0">
      <v-card-title
        class="px-0 mx-0 primary white--text font-weight-black headline"
        style="overflow: hidden"
      >
        <span class="ml-4">Equipment</span>
        <v-spacer></v-spacer>
        <v-btn
          class="mr-4"
          color="white"
          small
          dark
          outlined
          @click="closeEquipmentDialog"
          ><v-icon small class="mr-1">close</v-icon> close</v-btn
        >
        <!-- <v-spacer></v-spacer>
      <v-img src="@/assets/img/greegmv5.png" max-width="150"></v-img> -->
      </v-card-title>
      <v-card-text class="pt-4 px-1">
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="12" class="pa-0" v-if="loading">
              <v-skeleton-loader type="table"></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="12" class="pa-0" v-else>
              <v-tabs active-class="active-tab">
                <v-tab
                  v-for="category in categories"
                  :key="category"
                  @click="categoryChange(category)"
                  >{{ category }}</v-tab
                >
              </v-tabs>
              <v-divider></v-divider>
              <v-data-table
                dense
                :items="equipment"
                :loading="loading"
                loading-text="Loading....please wait."
                :headers="headersFiltered"
                :search="search"
              >
                <template v-slot:top>
                  <v-text-field
                    v-model="search"
                    label="Filter by product name or unit price"
                    clearable
                    outlined
                    dense
                    hide-details
                    class="mt-3 mb-2"
                  ></v-text-field>
                </template>
                <template v-slot:item.image="{ item }">
                  <img
                    v-if="item.image"
                    :src="item.image"
                    width="30"
                    height="auto"
                  />
                  <img
                    v-if="!item.image"
                    :src="noImage"
                    width="30"
                    height="auto"
                  />
                </template>
                <template v-slot:item.qty="{ item }">
                  <v-text-field
                    class="my-1"
                    outlined
                    dense
                    min="0"
                    hide-details
                    v-model.number="item.qty"
                    type="number"
                  ></v-text-field>
                </template>
                <template v-slot:item.model="{ item }">
                  {{item.model}}
                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        v-if="item.inactive"
                        small
                        color="error"
                        class="ml-1"
                        style="cursor: pointer;"
                      >
                        mdi-alert
                      </v-icon>
                    </template>
                    <span>Discontinued</span>
                  </v-tooltip>
                </template>
                <template v-slot:item.price="{ item }">
                  ${{ item.price.toFixed(2) }}
                </template>
                <template v-slot:item.subTotal="{ item }">
                  ${{
                    item.qty
                      ? (Math.round(item.qty * item.price * 100) / 100).toFixed(
                          2
                        )
                      : 0
                  }}
                </template>
                <template slot="body.append">
                  <tr>
                    <th
                      :colspan="headersFiltered.length - 1"
                      class="text-right"
                    >
                      SUB-TOTAL
                    </th>
                    <th class="text-right">{{ subTotal | toCurrency }}</th>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import models from "../../equipmentFiles/models.json";
import noImage from "@/assets/img/no-image.jpg";

import { sumBy, sortBy } from "lodash";
// import fb from "@/main";
export default {
  name: "Home",
  data() {
    return {
      noImage,
      models: [],
      equipment: [],
      allEquipment: [],
      loading: true,
      search: "",
      categories: [],
      headers: [
        { text: "", value: "image", width: "40", sortable: false },
        { text: "QTY", value: "qty", width: "120", sortable: false },
        {
          text: "Model",
          sortable: false,
          value: "model",
          width: "200",
        },
        {
          text: "Tonnage",
          value: "tonnage",
          width: "100",
        },
        {
          text: "BTU",
          value: "btu",
          width: "100",
        },
        {
          text: "Description",
          sortable: false,
          value: "description",
        },
        {
          text: "Voltage",
          value: "voltage",
        },
        {
          text: "Type",
          value: "productType",
        },
        { text: "Unit Price", value: "price", align: "end", width: "120" },
        { text: "Sub Total", value: "subTotal", align: "end", width: "120" },
      ],
    };
  },
  async created() {
    try {
      this.allEquipment = [...this.$store.state.equipment];
      if (
        !this.allEquipment.filter((e) => e.category === "AHU Kits & Mode Exch")
          .length
      ) {
        this.categories = [
          "Outdoor",
          "Indoor",
          "Controls",
          "Copper",
          "Mini Split",
          "Summary",
        ];
      } else {
        this.categories = [
          "Outdoor",
          "Indoor",
          "AHU Kits & Mode Exch",
          "Controls",
          "Copper",
          "Mini Split",
          "Summary",
        ];
      }
      const modelsGrouped = this.groupBy(models, "Model");
      this.allEquipment.forEach((e) => {
        e.image = (modelsGrouped[e.model] || [{}])[0]["Image URL"] || "";
      });
      this.equipment = this.allEquipment.filter(
        (e) => e.category === "Outdoor"
      );
      this.equipment = sortBy(this.equipment, "tonnage");
      this.headersFiltered = this.headers.filter((h) =>
        [
          "img",
          "qty",
          "model",
          "tonnage",
          "description",
          "voltage",
          "productType",
          "price",
          "subTotal",
        ].includes(h.value)
      );
      this.loading = false;
    } catch (error) {
      this.loading = false;
      alert("Error loading equipment.");
    }
  },
  computed: {
    subTotal() {
      const items = this.equipment.filter((e) => e.qty > 0);
      return this.equipment.length
        ? sumBy(items, (e) => e.qty * e.price).toFixed(2)
        : 0;
    },
  },
  methods: {
    groupBy(xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    closeEquipmentDialog() {
      const items = this.allEquipment.filter((e) => e.qty > 0);
      this.$emit("close", items);
      this.search = "";
    },
    categoryChange(category) {
      this.search = "";
      this.equipment = this.allEquipment.filter((e) => e.category === category);
      switch (category) {
        case "Outdoor":
          this.headersFiltered = this.headers.filter((h) =>
            [
              "img",
              "qty",
              "model",
              "tonnage",
              "description",
              "voltage",
              "productType",
              "price",
              "subTotal",
            ].includes(h.value)
          );
          this.equipment = sortBy(this.equipment, "tonnage");
          break;
        case "Indoor":
          this.headersFiltered = this.headers.filter((h) =>
            [
              "img",
              "qty",
              "model",
              "btu",
              "description",
              "voltage",
              "productType",
              "price",
              "subTotal",
            ].includes(h.value)
          );
          this.equipment = sortBy(this.equipment, "btu");
          break;
        case "AHU Kits & Mode Exch":
          this.headersFiltered = this.headers.filter((h) =>
            [
              "img",
              "qty",
              "model",
              "description",
              "price",
              "subTotal",
            ].includes(h.value)
          );
          this.equipment = sortBy(this.equipment, "model");
          break;
        case "Controls":
          this.headersFiltered = this.headers.filter((h) =>
            [
              "img",
              "qty",
              "model",
              "description",
              "price",
              "subTotal",
            ].includes(h.value)
          );
          this.equipment = sortBy(this.equipment, "model");
          break;
        case "Copper":
          this.headersFiltered = this.headers.filter((h) =>
            [
              "img",
              "qty",
              "model",
              "description",
              "price",
              "subTotal",
            ].includes(h.value)
          );
          this.equipment = sortBy(this.equipment, "model");
          break;
        case "Mini Split":
          this.headersFiltered = this.headers.filter((h) =>
            [
              "img",
              "qty",
              "model",
              "description",
              "price",
              "subTotal",
            ].includes(h.value)
          );
          this.equipment = sortBy(this.equipment, "model");
          break;
        case "Summary":
          this.equipment = this.allEquipment.filter((e) => e.qty > 0);
          this.headersFiltered = this.headers.filter((h) =>
            [
              "img",
              "qty",
              "model",
              "tonnage",
              "btu",
              "description",
              "voltage",
              "productType",
              "price",
              "subTotal",
            ].includes(h.value)
          );
          break;
      }
    },
  },
};
</script>
<style>
tbody tr:hover {
  background-color: transparent !important;
}
</style>
