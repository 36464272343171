<template>
  <v-container grid-list-xl>
    <admin-navigation></admin-navigation>
    <br/>
    <v-row>
      <v-col cols="12" sm="12" class="pb-0">
        <v-card class="elevation-0">
          <v-card-title
            class="ma-0 pa-4 orange lighten-3 font-weight-black headline"
            style="overflow: hidden"
          >
            <span class="ml-4" style="text-transform: uppercase;">Recent Quotes</span>
            <span class="caption mt-2 ml-2">Showing recent 1000</span>
          </v-card-title>
          <v-card-text class="pt-4 px-1">
            <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
            <v-container fluid>
              <v-row>
                <v-col cols="12" class="py-0" v-if="!loading">
                  <v-data-table
                    :headers="headers"
                    :items="quotes"
                    :search="search"
                    :items-per-page="100"
                  >
                    <template v-slot:top>
                      <v-text-field
                        v-model="search"
                        outlined
                        label="Filter by client or email or job details"
                        dense
                      ></v-text-field>
                    </template>
                    <template v-slot:item.domain="{ item }">
                      {{ item.createdBy.domain.split(".")[0] }}
                    </template>
                    <template v-slot:item.equipmentSummarySubtotal="{ item }">
                      ${{
                        Number(item.equipmentSummarySubtotal).toLocaleString(
                          "en-US"
                        )
                      }}
                      <span
                        class="caption grey--text d-block"
                        v-if="
                          item.equipmentSummarySubtotal !==
                          item.equipmentSummarySubtotalWithMarkup
                        "
                      >
                        ${{
                          Number(
                            item.equipmentSummarySubtotalWithMarkup
                          ).toLocaleString("en-US")
                        }}
                        with markup
                      </span>
                    </template>
                    <template v-slot:item.createDate="{ item }">
                      {{ item.createDate.toDate() | moment("M/D/YY") }}
                    </template>
                    <template v-slot:item.expirationDate="{ item }">
                      {{ new Date(item.expirationDate.seconds * 1000) | asUtc | moment("M/DD/YY") }}
                    </template>
                    <template v-slot:item.date="{ item }">
                      {{ item.job.date | moment("M/D/YY") }}
                    </template>
                    <template v-slot:item.edit="{ item }">
                      <v-btn
                        color="primary"
                        text
                        outlined
                        x-small
                        class="mr-2"
                        target="_blank"
                        @click="generatePDFView(item)"
                        >VIEW</v-btn
                      >
                      <v-btn
                        color="primary"
                        text
                        outlined
                        x-small
                        class="mr-2"
                        target="_blank"
                        @click="generatePDF(item)"
                        >PDF</v-btn
                      >
                    </template>
                    <template slot="no-data">
                      <span class="grey--text text--darken-3">
                        You have not created any quotes yet. Click the
                        <strong>"Create New"</strong>
                        button above.
                      </span>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import moment from "moment";
import fb from "@/main";
import { mapGetters } from "vuex";
import { sortBy } from "lodash";
import AdminNavigation from "@/components/AdminNavigation.vue";

export default {
  components: {
    AdminNavigation
  },
  name: "Home",
  data() {
    return {
      dialogDelete: false,
      search: "",
      loading: true,
      dataExists: false,
      snack: false,
      snackColor: "",
      snackText: "",
      estDate: null,
      headers: [
        {
          text: "User",
          value: "createdBy.email",
        },
        {
          text: "Job",
          value: "job.jobName",
        },
        {
          text: "Created",
          value: "createDate"
        },
        {
          text: "Exp Date",
          value: "expirationDate"
        },
        {
          text: "Total",
          value: "equipmentSummarySubtotal",
        },
        {
          text: "",
          value: "edit",
          align: "end",
          width: 140
        },
      ],
      quotes: [],
    };
  },
  async created() {
    this.getQuotes();
  },
  computed: {
    formattedDate() {
      return this.job.date ? moment(this.job.date).format("MMMM Do YYYY") : "";
    },
    formattedEstDate() {
      return this.job.estDate
        ? moment(this.job.estDate).format("MMMM Do YYYY")
        : "";
    },
    ...mapGetters(["user"]),
  },
  methods: {
    async getQuotes() {
      try {
        const quotesRes = await fb.db.collection("quotes").orderBy('createDate', 'desc').limit(1000).get()
        const quotes = [];
        quotesRes.forEach((q) => {
          quotes.push({
            ...q.data(),
            id: q.id,
          });
        });
        console.log("quotes", quotes);
        this.quotes = sortBy(quotes, "createDate").reverse();
        this.loading = false;
      } catch (error) {
        if (error.message === "Missing or insufficient permissions.") {
          this.$router.push("/");
        } else {
          alert("Error: ", error.message);
        }
      }
    },
    generatePDFView(quote) {
      let url;
      if (location.hostname === "localhost") {
        url = `http://localhost:5001/gree-commercial/us-central1/generatePdf?id=${quote.id}&view=1`;
      } else {
        url = `https://us-central1-gree-commercial.cloudfunctions.net/generatePdf?id=${quote.id}&view=1`;
      }
      window.open(url, "_blank");
    },
    generatePDF(quote) {
      let url;
      if (location.hostname === "localhost") {
        url = `http://localhost:5001/gree-commercial/us-central1/generatePdf?id=${quote.id}`;
      } else {
        url = `https://us-central1-gree-commercial.cloudfunctions.net/generatePdf?id=${quote.id}`;
      }
      window.open(url, "_blank");
    },
  },
};
</script>
