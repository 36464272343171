var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-xl":""}},[_c('admin-navigation'),_c('br'),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12"}},[_c('v-card',{staticClass:"elevation-0"},[_c('v-card-title',{staticClass:"ma-0 pa-4 orange lighten-3 font-weight-black headline",staticStyle:{"overflow":"hidden"}},[_c('span',{staticClass:"ml-4",staticStyle:{"text-transform":"uppercase"}},[_vm._v("Recent Quotes")]),_c('span',{staticClass:"caption mt-2 ml-2"},[_vm._v("Showing recent 1000")])]),_c('v-card-text',{staticClass:"pt-4 px-1"},[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_vm._e(),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[(!_vm.loading)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.quotes,"search":_vm.search,"items-per-page":100},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"outlined":"","label":"Filter by client or email or job details","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.domain",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.createdBy.domain.split(".")[0])+" ")]}},{key:"item.equipmentSummarySubtotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(Number(item.equipmentSummarySubtotal).toLocaleString( "en-US" ))+" "),(
                        item.equipmentSummarySubtotal !==
                        item.equipmentSummarySubtotalWithMarkup
                      )?_c('span',{staticClass:"caption grey--text d-block"},[_vm._v(" $"+_vm._s(Number( item.equipmentSummarySubtotalWithMarkup ).toLocaleString("en-US"))+" with markup ")]):_vm._e()]}},{key:"item.createDate",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createDate.toDate(),"M/D/YY"))+" ")]}},{key:"item.expirationDate",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(_vm._f("asUtc")(new Date(item.expirationDate.seconds * 1000)),"M/DD/YY"))+" ")]}},{key:"item.date",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.job.date,"M/D/YY"))+" ")]}},{key:"item.edit",fn:function(ref){
                      var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","text":"","outlined":"","x-small":"","target":"_blank"},on:{"click":function($event){return _vm.generatePDFView(item)}}},[_vm._v("VIEW")]),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","text":"","outlined":"","x-small":"","target":"_blank"},on:{"click":function($event){return _vm.generatePDF(item)}}},[_vm._v("PDF")])]}}],null,false,1815792620)},[_c('template',{slot:"no-data"},[_c('span',{staticClass:"grey--text text--darken-3"},[_vm._v(" You have not created any quotes yet. Click the "),_c('strong',[_vm._v("\"Create New\"")]),_vm._v(" button above. ")])])],2)],1):_vm._e()],1)],1)],1)],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snack = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }