<template>
  <div id="app">
    <v-app>
      <v-app-bar
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        app
        color="primary"
        dark
        v-if="privateRoute()"
      >
        <img
          src="https://www.greecommercial.com/assets/logo/logo-inverse.png"
          width="100"
          class="d-inline-block ml-3"
          @click="goHome"
          style="cursor: pointer"
        /><span style="font-size: 12px; opacity: 0.7" class="mt-3 ml-1"
          >v0.14</span
        >
        <v-spacer></v-spacer>
        <v-btn text class="mr-4" outlined to="/">Home</v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-avatar :size="36" class="secondary lighten-1">
                <v-icon color="primary">person</v-icon>
              </v-avatar>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-if="isInternalAdministrator"
            >
              <v-list-item-title
                ><v-btn color="primary" text to="/admin/dashboard"
                  >Admin</v-btn
                ></v-list-item-title
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-title
                ><v-btn color="primary" text to="/profile"
                  >profile</v-btn
                ></v-list-item-title
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-title
                ><v-btn color="primary" text @click="signout"
                  >signout</v-btn
                ></v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>

      <v-main class="content">
        <div class="pa-2">
          <router-view />
        </div>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import fb from "@/main";
export default {
  name: "App",
  data: () => ({
    dialog: false,
    drawer: null,
    isInternalAdministrator: false
  }),
  computed: {
    ...mapGetters(["user"]),
  },
  async created() {
    console.log(this.user)
    var isInternalAdministrator = fb.functions.httpsCallable("isInternalAdministrator");
    const {data: admin} = await isInternalAdministrator();
    this.isInternalAdministrator = admin;
  },
  methods: {
    privateRoute() {
      return this.$route.meta.requiresAuth;
    },
    signout: async function () {
      try {
        await fb.auth.signOut();
        this.$router.push("/login");
      } catch (error) {
        alert(error);
      }
    },
    goHome() {
      if (this.$route.path !== "/") {
        this.$router.push("/");
      } else {
        return;
      }
    },
  },
};
</script>

<style>
.content {
  background: whitesmoke;
}
</style>
