<template>
  <div
    v-bind:style="{ backgroundImage: 'url(' + image + ')' }"
    class="login-bg"
  >
    <v-main class="pt-4 mt-4">
      <v-container fluid fill-height grid-list-md>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12 signup-card">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12>
                    <div>
                      <div class="headline text-center">
                        <img src="../assets/img/gree-logo.png" width="250" />
                      </div>
                      <h2 class="text-center font-weight-regular">
                        GREE COMMERCIAL
                      </h2>
                    </div>

                    <br />
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-layout row wrap>
                        <v-flex xs12 sm6>
                          <v-text-field
                            v-model="firstName"
                            label="First Name"
                            :rules="[(v) => !!v || 'First Name is required']"
                            required
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6>
                          <v-text-field
                            v-model="lastName"
                            label="Last Name"
                            :rules="[(v) => !!v || 'Last Name is required']"
                            required
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                            v-model="phone"
                            v-mask="'(###) ###-####'"
                            label="Contact Phone number"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                            v-model="email"
                            label="Email"
                            required
                            :rules="emailRules"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                            v-model="password"
                            :rules="passwordRules"
                            label="Create Password"
                            required
                            type="password"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <br />
                      <div class="grey--text">
                        By creating an account, you are agreeing to our Terms of
                        Service and Privacy Policy
                      </div>
                      <br />
                      <v-btn
                        :disabled="!valid || loading"
                        :loading="loading"
                        @click="submit"
                        block
                        color="primary"
                        large
                        >Create Account</v-btn
                      >
                      <br />
                      <p class="text-xs-center">
                        Already registered?
                        <a href="/login">Sign In</a>.
                      </p>
                    </v-form>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import fb from "@/main";
import image from "@/assets/img/bg.jpg";
import { mask } from "vue-the-mask";
export default {
  name: "login",
  directives: {
    mask,
  },
  data() {
    return {
      loading: false,
      image,
      firstName: "",
      lastName: "",
      valid: true,
      username: "",
      email: "",
      phone: "",
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      password: "",
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length > 5) || "password must be more than 5 characters",
      ],
      snackbar: false,
    };
  },
  methods: {
    async submit(e) {
      this.loading = true;
      if (this.$refs.form.validate()) {
        try {
          this.email = this.email.trim();
          this.email = this.email.toLowerCase().trim();
          const domain = this.email.split("@")[1];
          var companyCheck = fb.functions.httpsCallable("companyCheck");
          const { data: res } = await companyCheck({ domain });
          if (!res.valid) {
            alert("Company not found.");
            this.loading = false;
            return;
          }
          const authData = await fb.auth.createUserWithEmailAndPassword(
            this.email,
            this.password
          );
          if (authData.user) {
            const userData = {
              firstName: this.firstName,
              lastName: this.lastName,
              phone: this.phone,
              email: this.email,
              uid: authData.user.uid,
              createDate: fb.timestamp,
              domain: this.email.split("@")[1].toLowerCase(),
            };
            fb.db.doc(`users/${authData.user.uid}`).set(userData);
            this.$store.commit("cacheUser", userData);
            await authData.user.sendEmailVerification();
            this.loading = false;
            this.$router.push("/verification");
          }
        } catch (error) {
          this.loading = false;
          console.log(error);
          alert(error.message);
        }
      }
      e.preventDefault();
    },
  },
};
</script>

<style scoped>
.signup-card {
  padding: 15px;
}
.login-bg {
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
</style>
