import Vue from "vue";
import Router from "vue-router";
import fb from '@/main';
import store from "@/state/store";

import Home from "@/views/Home.vue";
import Quote from "@/views/Quote.vue";
import Equipment from "@/views/Equipment.vue";
import Profile from "./views/Profile.vue";
import Login from "./views/Login.vue";
import Signup from "./views/Signup.vue";
import Verification from "./views/Verification.vue";
import Terms from "./views/Terms.vue";

// admin
import Pricing from "@/views/Admin/Pricing.vue";
import Clients from "@/views/Admin/Clients.vue";
import AllQuotes from "@/views/Admin/AllQuotes.vue";
import Dashboard from "@/views/Admin/Dashboard.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: { requiresAuth: true }
    },
    {
      path: "/quote",
      name: "new-quote",
      component: Quote,
      meta: { requiresAuth: true }
    },
    {
      path: "/quote/:quoteId",
      name: "edit-quote",
      component: Quote,
      meta: { requiresAuth: true }
    },
    {
      path: "/equipment",
      name: "equipment",
      component: Equipment,
      meta: { requiresAuth: true }
    },
    {
      path: "/admin/pricing",
      name: "pricing",
      component: Pricing,
      meta: { requiresAuth: true }
    },
    {
      path: "/admin/clients",
      name: "clients",
      component: Clients,
      meta: { requiresAuth: true }
    },
    {
      path: "/admin/quotes",
      name: "allquotes",
      component: AllQuotes,
      meta: { requiresAuth: true }
    },
    {
      path: "/admin/dashboard",
      name: "dashboard",
      component: Dashboard,
      meta: { requiresAuth: true }
    },
    {
      path: "/profile",
      name: "profile",
      component: Profile,
      meta: { requiresAuth: true }
    },
    {
      path: "/verification",
      name: "verification",
      component: Verification,
    },
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/signup",
      name: "signup",
      component: Signup
    },
    {
      path: "/terms",
      name: "terms",
      component: Terms
    },
  ]
});
router.beforeEach(async (to, from, next) => {
  // Check for requiresAuth guard
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if NO logged userd
    if (!fb.auth.currentUser) {
      next({
        path: '/login'
      });
    } else {
      if(store.getters['user']){
        // don't need to fetch user, already cached
        if(!fb.auth.currentUser.emailVerified){
          next({
            path: '/verification'
          });
        } else {
          next()
        }
      } else {
        if(!fb.auth.currentUser.emailVerified){
          next({
            path: '/verification'
          });
        } else {
          await store.dispatch('getUser')
          next();
        }
      }
    }
  } else {
    // Proceed to route
    next()
  }
});
export default router;
