<template>
  <v-bottom-navigation>
    <v-btn :to="`/admin/dashboard`">
      <span>Dashboard</span>
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>

    <v-btn :to="`/admin/pricing`">
      <span>Pricing</span>
      <v-icon>mdi-currency-usd</v-icon>
    </v-btn>

    <v-btn :to="`/admin/quotes`">
      <span>Quotes</span>
      <v-icon>mdi-note-multiple-outline</v-icon>
    </v-btn>

    <v-btn :to="`/admin/clients`">
      <span>Clients</span>
      <v-icon>mdi-account-group</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  props: [],
  data() {
    return {
    };
  },
};
</script>
