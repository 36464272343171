<template>
  <v-container grid-list-xl>
    <admin-navigation></admin-navigation>
    <br/>
    <v-row>
      <v-col cols="12" sm="12" class="pb-0">
        <v-card class="elevation-0">
          <v-card-title
            class="ma-0 pa-4 orange lighten-3 font-weight-black headline"
            style="overflow: hidden"
          >
            <span class="ml-4" style="text-transform: uppercase;">Dashboard</span>
            <span class="caption mt-2 ml-2">Showing quoted products from {{quotes.length}} quotes</span>
            <v-spacer></v-spacer>
            <v-btn small color="primary" outlined @click="getCsv" v-show="quotes.length">
              <v-icon left>download</v-icon>Export Report
            </v-btn>
          </v-card-title>
          <v-card-text class="pt-4 px-1">
            <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="6" md="3" class="py-0 mb-5">
                  <v-menu
                    ref="dialogDatePicker"
                    v-model="dialogDatePicker"
                    :close-on-content-click="false"
                    :return-value.sync="filter.dates"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateRangeText"
                        label="Date Range"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="filter.dates" range>
                      <v-spacer></v-spacer>
                      <v-btn text @click="dialogDatePicker = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.dialogDatePicker.save(filter.dates);
                          getLeaderboard();
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="py-0" v-if="!loading">
                  <v-data-table
                    :headers="headers"
                    :items="leaderboard"
                    :search="search"
                    :items-per-page="100"
                    hide-default-footer
                  >
                    <template v-slot:top>
                      <v-text-field
                        v-model="search"
                        outlined
                        label="Filter by model or description or category"
                        dense
                      ></v-text-field>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import fb from "@/main";
import { mapGetters } from "vuex";
import { sortBy } from "lodash";
import AdminNavigation from "@/components/AdminNavigation.vue";

export default {
  components: {
    AdminNavigation
  },
  name: "Home",
  data() {
    return {
      search: "",
      loading: false,
      leaderboard: [],
      quotes: [],
      dateRangeString: "",
      dialogDatePicker: false,
      filter: {
        dates: [moment().subtract(14, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
        barcodeId: undefined,
        jobSite: undefined,
        punchDirection: "in & out",
      },
      headers: [
        {
          text: "Model",
          value: "model",
        },
        {
          text: "Description",
          value: "description",
          width: 500,
        },
        {
          text: "Category",
          value: "category",
        },
        {
          text: "Quoted",
          value: "qty",
        },
      ],
    };
  },
  async created() {
    this.getLeaderboard();
  },
  computed: {
    ...mapGetters(["user"]),
    dateRangeText: function() {
      return this.filter.dates.join(" ~ ");
    },
  },
  methods: {
    groupBy(xs, key) {
      return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    getCsv: function() {
      try {
        const csvData = [];
        this.leaderboard.forEach((t) => {
          if (!t.error) {
            csvData.push({
              Model: t.model,
              Description: t.description,
              Category: t.category,
              Quoted: t.qty,
            });
          }
        });
        if (!csvData.length) {
          alert("No data was found. Try again.");
          return;
        }
        const replacer = (key, value) => (value === null ? "" : `${value}`); // specify how you want to handle null values here
        const header = Object.keys(csvData[0]);
        const csvFile = [
          header.join(","), // header row first
          ...csvData.map((row) =>
            header
              .map((fieldName) => JSON.stringify(row[fieldName], replacer))
              .join(",")
          ),
        ].join("\r\n");
        // START setup for downloading csv blob
        const filename = `report-${this.dateRangeString}.csv`;
        const blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, filename);
        } else {
          var link = document.createElement("a");
          if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
        // END setup for downloading csv blob
      } catch (e) {
        alert("No data was found. Try again.");
        console.log("getCsv error", e);
      }
    },
    async getLeaderboard() {
      try {
        this.loading = true;
        const dates = this.filter.dates.map((d) => {
          return new Date(d);
        });

        // end date of range selector
        const endDate = new Date(Math.max.apply(null, dates));
        const utcOffsetHours = moment().utcOffset() / 60;
        endDate.setUTCHours(23 - utcOffsetHours, 59, 59, 999);

        // start date of range selector
        const startDate = new Date(Math.min.apply(null, dates));
        startDate.setUTCHours(utcOffsetHours * -1, 0, 0, 0);

        const quotesRes = await fb.db.collection("quotes")
          .where("createDate", ">=", startDate)
          .where("createDate", "<=", endDate)
          .get();

        const quotes = [];
        quotesRes.forEach((q) => {
          quotes.push({
            ...q.data(),
            id: q.id,
          });
        });

        const equipment = [];
        quotes.forEach((q) => {
          q.equipment.forEach((qe) => {
            equipment.push(qe);
          })
        });

        const sumOfObjects = (arr, all = {}) => (
          arr.forEach(({ model, qty }) => (all[model] = (all[model] ?? 0) + qty)), all
        );

        const equipmentGroupedSums = sumOfObjects(equipment)
        const equipmentGroupedArrays = this.groupBy(equipment, 'model');

        const leaderboard = [];
        Object.keys(equipmentGroupedArrays).forEach((key) => {
          const record = equipmentGroupedArrays[key][0];
          record.qty = equipmentGroupedSums[key];
          leaderboard.push(record);
        });

        this.leaderboard = sortBy(leaderboard, "qty").reverse();
        this.quotes = quotes;
        this.dateRangeString = `${moment(startDate).format("YYYY-MM-DD")}-${moment(endDate).format("YYYY-MM-DD")}`;
        this.loading = false;
      } catch (error) {
        if (error.message === "Missing or insufficient permissions.") {
          this.$router.push("/");
        } else {
          alert("Error: ", error.message);
        }
      }
    },
  },
};
</script>
