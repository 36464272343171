import Vue from "vue";
import Vuex from "vuex";
import router from  "@/router"
import fb from "@/main"
Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        user: null,
        equipment: []
    },
    getters: {
        user: (state) => {
            return state.user;
          },
          equipment: (state) => {
            return state.equipment;
          },
    },
    mutations: {
        cacheUser(state, user) {
            state.user = user;
          },
        cacheEquipment(state, equipment) {
            state.equipment = equipment;
          },
    },
    actions: {
        signout: async function () {
            try {
              await fb.auth.signOut();
              router.push("/login");
            } catch (error) {
              alert(error);
            }
          },
          getUser: async function ({ commit }) {
            try {
              const { uid } = await fb.auth.currentUser;
              const userRes = await fb.db.collection('users').doc(uid).get()
              let user = userRes.data()
              commit("cacheUser", user)
            } catch (error) {
              alert(error);
            }
          },
          loadEquipment: async function ({ commit }, equipmentToBeLoaded) {
            const getEquipment = fb.functions.httpsCallable('getEquipment');
            const {data:equipment} =  await getEquipment()
            equipment.forEach(e => {
              const found = equipmentToBeLoaded.find(i => e.model === i.model)
              if(found){
                e.qty = found.qty
              }
            });
            const activeEquipment = equipment.filter((e) => {return !e.inactive || e.qty > 0})
            commit("cacheEquipment", activeEquipment)
          },
          getEquipment: async function ({ commit }) {
            try {
              const getEquipment = fb.functions.httpsCallable('getEquipment');
              const {data:equipment} =  await getEquipment()
              const activeEquipment = equipment.filter((e) => {return !e.inactive})
              commit("cacheEquipment", activeEquipment)
            } catch (error) {
              console.log(error);
              alert(error);
            }
          },
    }
})

export default store;